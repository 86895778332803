<template>
  <s-dialog to="stds-dialog-banner-image" size="md" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!w-[93.2rem]">
      <st-dialog-header @clickClose="closeDialog">
        <span class="font-bold text-wrap">{{
          $t('studio.prj_prod.this_prod.marketing_banner_preview_title')
        }}</span>
      </st-dialog-header>
      <s-dialog-content class="dark">
        <p
          class="text-sm leading-md font-regular text-[var(--stds-sem-color-on-surface-elevation-5)]"
        >
          {{ $t('studio.prj_prod.this_prod.marketing_banner_preview_msg') }}
        </p>
        <div class="mt-12 py-[3rem] px-[4.4rem] rounded-3xl bg-background-variant-1">
          <div class="grid grid-cols-5 grid-rows-2 gap-12 h-[30.8rem]">
            <div
              class="col-span-2 row-span-2 rounded-3xl overflow-hidden relative after:absolute after:top-0 after:inset-x-0 after:h-36 after:bg-[linear-gradient(180deg,_rgba(0,0,0,.80),_rgba(0,0,0,.00))] before:absolute before:bottom-0 before:inset-x-0 before:h-68 before:bg-[linear-gradient(180deg,_rgba(0,0,0,.00),_rgba(0,0,0,.80))]"
            >
              <img
                v-if="!image2x2"
                src="@/assets/images/banner/thumbnails-2x2.svg"
                alt="thumbnails 2x2"
                class="w-full h-full object-cover"
              />
              <img v-else :src="image2x2" alt="image2x2" class="w-full h-full object-cover" />
              <div class="absolute z-[2] left-[1.4rem] bottom-12 flex gap-4 flex-col">
                <p class="text-md leading-sm text-white font-bold">{{ productName }}</p>
                <span class="text-xs leading-xs text-white font-bold">₩00,000</span>
              </div>
            </div>
            <div
              class="col-span-1 row-span-2 rounded-3xl overflow-hidden relative after:absolute after:top-0 after:inset-x-0 after:h-60 after:bg-[linear-gradient(180deg,_rgba(0,0,0,.80),_rgba(0,0,0,.00))] before:absolute before:bottom-0 before:inset-x-0 before:h-[4.2rem] before:bg-[linear-gradient(180deg,_rgba(0,0,0,.00),_rgba(0,0,0,.80))]"
            >
              <img
                v-if="!image1x2"
                src="@/assets/images/banner/thumbnails-1x2.svg"
                alt="thumbnails 1x2"
                class="w-full h-full object-cover"
              />
              <img v-else :src="image1x2" alt="image1x2" class="w-full h-full object-cover" />
              <div class="absolute z-[2] left-[1.4rem] top-12">
                <p class="text-md leading-sm text-white font-bold">{{ productName }}</p>
              </div>
              <div class="absolute z-[2] left-[1.4rem] bottom-12">
                <span class="text-xs leading-xs text-white font-bold">₩00,000</span>
              </div>
            </div>
            <div
              class="col-span-2 row-span-1 rounded-3xl overflow-hidden relative after:absolute after:top-0 after:inset-x-0 after:h-[4.2rem] after:bg-[linear-gradient(180deg,_rgba(0,0,0,.80),_rgba(0,0,0,.00))] before:absolute before:bottom-0 before:inset-x-0 before:h-[4.2rem] before:bg-[linear-gradient(180deg,_rgba(0,0,0,.00),_rgba(0,0,0,.80))]"
            >
              <img
                v-if="!image2x1"
                src="@/assets/images/banner/thumbnails-2x1.svg"
                alt="thumbnails 2x1"
                class="w-full h-full object-cover"
              />
              <img v-else :src="image2x1" alt="image2x1" class="w-full h-full object-cover" />
              <div class="absolute z-[2] left-[1.4rem] top-12">
                <p class="text-md leading-sm text-white font-bold">{{ productName }}</p>
              </div>
              <div class="absolute z-[2] left-[1.4rem] bottom-12">
                <span class="text-xs leading-xs text-white font-bold">₩00,000</span>
              </div>
            </div>
            <div
              class="col-span-1 row-span-1 rounded-3xl overflow-hidden bg-surface-variant-1 flex flex-col"
            >
              <div class="h-[8.2rem] overflow-hidden">
                <img
                  src="@/assets/images/banner/thumbnail-demo1.jpg"
                  alt="thumbnail demo1"
                  class="w-full h-full object-cover"
                />
              </div>
              <div class="flex-1 flex flex-col pt-8 pb-12 px-12">
                <p class="text-2xs leading-sm text-on-surface-elevation-1 font-bold">GAME TITLE</p>
                <span class="mt-auto text-2xs leading-xs text-on-surface-elevation-1 font-bold">₩00,000</span>
              </div>
            </div>
            <div
              class="col-span-1 row-span-1 rounded-3xl overflow-hidden bg-surface-variant-1 flex flex-col"
            >
              <div class="h-[8.2rem] overflow-hidden">
                <img
                  src="@/assets/images/banner/thumbnail-demo2.jpg"
                  alt="thumbnail demo2"
                  class="w-full h-full object-cover"
                />
              </div>
              <div class="flex-1 flex flex-col pt-8 pb-12 px-12">
                <p class="text-2xs leading-sm text-on-surface-elevation-1 font-bold">GAME TITLE</p>
                <span class="mt-auto text-2xs leading-xs text-on-surface-elevation-1 font-bold">₩00,000</span>
              </div>
            </div>
          </div>
        </div>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="sm:min-w-264" @click="closeDialog">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-banner-image" />
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

import StDialogHeader from '@/components/common/st-dialog-header.vue';

const emit = defineEmits<{
  close: [void];
}>();

const props = defineProps<{
  productName: string;
  image2x2: string;
  image1x2: string;
  image2x1: string;
}>();

const { productName, image2x2, image1x2, image2x1 } = toRefs(props);

const closeDialog = () => {
  emit('close');
};
</script>
